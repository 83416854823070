import {ProfilePic, ProfilCard, MobileProfileCard} from "../common/profilCard";

export const Profile = (props) => {
    return (
        <>
            <ProfilCard/>
        </>
    )
}

export const MobileProfile = (props) => {
    return (
        <>
            <MobileProfileCard/>
        </>
    )
}